import React from "react"
import { graphql, StaticQuery, Link, navigate } from "gatsby"
import styled from "styled-components"
import anime from "animejs"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import LogoTooltip from "src/components/LogoTooltip"
import { PrimaryTitle } from "src/components/Typography"
import { slugify } from "src/utils/helpers"
import AiMap from "src/assets/images/inline-svg/ai-map.svg"

class OrganisationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      observed: false,
      activeCountry: null,
      lastAnimatedPoint: null,
    }
    this.mapWrapper = React.createRef()
    this.mapPoints = React.createRef()
  }

  componentDidMount() {
    this.setState({
      observed: true,
    })
    const paths = [...this.mapWrapper.current.querySelectorAll(".country-ai")]
    paths.forEach((path) => {
      path.addEventListener("mouseenter", this.handleMapEnter)
      path.addEventListener("mouseleave", this.handleMapLeave)
    })
    this.mapWrapper.current.addEventListener("click", this.handleMapClick)

    setTimeout(() => {
      this.animatePoint()
    }, 1000)
  }

  componentWillUnmount() {
    const paths = [...this.mapWrapper.current.querySelectorAll(".country-ai")]
    paths.forEach((path) => {
      path.removeEventListener("mouseenter", this.handleMapEnter)
      path.removeEventListener("mouseleave", this.handleMapLeave)
    })
    this.mapWrapper.current.removeEventListener("click", this.handleMapClick)
  }

  animatePoint() {
    const animate = () => {
      if (!this.mapPoints.current) return

      const points = this.mapPoints.current.childNodes
      const index =
        this.state.lastAnimatedPoint === null ||
        this.state.lastAnimatedPoint + 2 > points.length
          ? 0
          : this.state.lastAnimatedPoint + 1
      const point = points[index]

      anime({
        targets: point.firstChild,
        opacity: [1, 0],
        scale: [0, 1],
        duration: 2500,
        easing: "easeOutQuad",
        complete: () => {
          if (typeof this.animatePoint !== "function") {
            return
          }
          this.setState({
            lastAnimatedPoint: index,
          })
          this.animatePoint()
        },
      })
    }

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    setTimeout(animate, getRandomInt(0, 4000))
  }

  toggleMapHighlight(index, country, on = true) {
    const countryShort = country === "united kingdom" ? "uk" : country
    this.setState({
      activeCountry: on ? countryShort : null,
    })
    const path = document.getElementById(`path-${countryShort}`)
    path && path.classList[on ? "add" : "remove"]("-active")
  }

  handleMapClick = (event) => {
    if (event.target.classList.contains("country-ai")) {
      const countrySlug = event.target.id.substr(5)
      const slug = slugify(this.getOrgByCountrySlug(countrySlug).name)
      navigate(`/about/organisations/${slug}`)
    }
  }

  handleMapEnter = (event) => {
    const country = event.target.id.substr(5)
    this.setState({
      activeCountry: country,
    })
  }

  handleMapLeave = (event) => {
    this.setState({
      activeCountry: null,
    })
  }

  /**
   * @param {string} countrySlug = the name of the country an org operates in
   * @returns the name of an org which operates in countrySlug
   */
  getOrgByCountrySlug(countrySlug) {
    for (let i = 0; i < this.props.organisations.length; i++) {
      let org = this.props.organisations[i]
      if (slugify(org.country.name) === countrySlug)  {
        return org
      }
    }
  }

  render() {
    return (
      <Wrapper className={this.state.observed ? " -observed" : ""}>
        <Seo
          title="Organisations of Anima International"
          description="Meet the organizations that are part of Anima International. We work in 10 countries, focusing on key areas to effectively help farm animals."
        />
        <MapWrapper ref={this.mapWrapper}>
          <Map />
          <Points ref={this.mapPoints}>
            {this.props.organisations.map(
              ({ country, logoFileName }, index) => (
                <Point key={index}>
                  <PointRing />
                </Point>
              )
            )}
          </Points>
        </MapWrapper>
        <Container>
          <Title as="h1">
            Organisations of
            <br /> Anima International
          </Title>
          <Menu>
            {this.props.organisations.map(
              ({ name, original_name: originalName, country, logo }, index) => {
                const slug = slugify(name)
                const countrySlug = slugify(country?.name)
                const logoSrc = logo.length && logo[0].url

                return (
                  <MenuItem
                    key={index}
                    className={
                      countrySlug === this.state.activeCountry ? "-active" : ""
                    }
                  >
                    <MenuLink
                      to={`/about/organisations/${slug}`}
                      onMouseEnter={() => {
                        this.toggleMapHighlight(index, countrySlug)
                      }}
                      onMouseLeave={() => {
                        this.toggleMapHighlight(index, countrySlug, false)
                      }}
                    >
                      <MenuItemCountry>{country.name}</MenuItemCountry>
                      <MenuItemName>{originalName || name}</MenuItemName>
                      <MenuItemTooltip
                        country={countrySlug}
                        logoSrc={logoSrc}
                      />
                    </MenuLink>
                  </MenuItem>
                )
              }
            )}
          </Menu>
        </Container>
      </Wrapper>
    )
  }
}

const Page = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiOrganisation(
          sort: { fields: country___name }
          filter: { name: { nin: "Anima International" } }
        ) {
          nodes {
            name
            original_name
            country {
              name
            }
            logo {
              url
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <OrganisationsPage organisations={data.allStrapiOrganisation.nodes} />
      )
    }}
  />
)

const Wrapper = styled.div`
  margin-bottom: 160px;
  position: relative;

  ${media.tablet} {
    margin-bottom: 220px;
  }
`

const MapWrapper = styled.div`
  display: none;
  width: 100%;
  height: 1493px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.61);
  transform-origin: 100% 50%;

  &::before {
    content: "";
    width: 100%;
    height: 693px;
    position: absolute;
    top: 800px;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 400px
    );
    pointer-events: none;
  }

  ${media.tablet} {
    display: block;
  }

  ${media.desktop} {
    transform: none;
  }
`

const Map = styled(AiMap)`
  width: 4600px;
  max-width: none;
  position: absolute;
  top: -20px;
  left: 50%;
  /* transform: scale(0.61) translateX(calc(-50% + 250px)); */
  /* transform-origin: 0% 30%; */
  transform: translateX(calc(-50% + 10px));
  user-select: none;

  path {
    &.country-ai {
      cursor: pointer;
      transition: fill 0.15s;

      &:hover,
      &.-active {
        fill: #3e3d4e;
      }
    }
  }

  ${media.desktop} {
    transform: translateX(calc(-50% + 175px));
  }

  ${media.desktopMedium} {
    transform: translateX(calc(-50% + 200px));
  }
`

const Points = styled.div`
  pointer-events: none;
  transform: translateX(-165px);

  ${media.desktop} {
    transform: none;
  }
`

const Point = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: -25px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 11, 33, 0.1);

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    position: relative;
    z-index: 1;
    background: #3e3d4e;
    border-radius: 50%;
  }

  // DE
  &:nth-child(1) {
    top: 620px;
    left: calc(50% + 207px);
  }

  // NO
  &:nth-child(2) {
    top: 540px;
    left: calc(50% + 185px);
  }

  // UK
  &:nth-child(3) {
    top: 687px;
    left: calc(50% + 42px);
  }

  // PL
  &:nth-child(4) {
    top: 671px;
    left: calc(50% + 310px);
  }

  // UA
  &:nth-child(5) {
    top: 721px;
    left: calc(50% + 449px);
  }

  // FR
  &:nth-child(6) {
    top: 736px;
    left: calc(50% + 75px);
  }

  // BG
  &:nth-child(7) {
    top: 835px;
    left: calc(50% + 350px);
  }

  ${media.desktopMedium} {
    margin-left: 0;
  }
`

const PointRing = styled.div`
  opacity: 0;
  position: absolute;
  top: -30px;
  right: -30px;
  bottom: -30px;
  left: -30px;
  background: rgba(170, 95, 209, 0.5);
  border: 1px solid rgba(170, 95, 209, 1);
  border-radius: 50%;
`

const Container = styled(BaseContainer)`
  padding-top: 180px;
  position: relative;
  z-index: 1;
  pointer-events: none;

  ${media.tablet} {
    padding-top: 250px;
  }
`

const Title = styled(PrimaryTitle)`
  max-width: 11em;
  margin: 0 auto 70px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  ${Wrapper}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-bottom: 80px;
  }
`

const Menu = styled.ul`
  width: 340px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0;
  position: relative;
  background: #fff;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.08);
  border-radius: 3px;
  font-size: 15px;
  pointer-events: all;
  transition: 0.6s 0.15s ${(p) => p.theme.easing.easeOutQuart};

  ${Wrapper}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`

const MenuItem = styled.li``

const MenuLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: 12px 35px;
  position: relative;
  /* border-radius: 3px; */

  ${media.pointer} {
    &:hover,
    ${MenuItem}.-active & {
      background: #d5f5f3;
    }
  }
`

const MenuItemCountry = styled.span`
  position: relative;
  top: 1px;
  font-weight: 500;
  color: #000;
`

const MenuItemName = styled.span`
  position: relative;
  top: 1px;
  font-size: 15px;
  color: ${(p) => p.theme.color.textLight};

  @media (max-width: 370px) {
    top: 4px;
    font-size: 11px;
  }
`

const MenuItemTooltip = styled(LogoTooltip)`
  ${media.pointer} {
    ${MenuItem}.-active & {
      visibility: visible;
    }
  }
`

export default Page
